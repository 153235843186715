import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import { CreateHttpClient } from "src/providers/dataProvider";
import { User } from "src/types/types";
import { RootState } from "../store";
import { IUser, IUserSlice } from "./userSlice.contracts";

const initialState: IUserSlice = {
  user: null,
  authToken: null,
  loading: false,
  test: "",
};

const httpClient = CreateHttpClient<User>("User");

export const getUserData = createAsyncThunk(
  "authUser/getUserData",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const ret = await httpClient.getOne(params, state.user.authToken as string);
    return ret.data;
  }
);

export const updateUser = createAsyncThunk(
  "authUser/updateUser",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const ret = await httpClient.update(params, state.user.authToken as string);
    return ret.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<{ user: IUser }>) {
      const { user } = action.payload;
      state.user = user;
    },

    /** Make shift reducer for navigating between user roles to be deleted */
    changeUserRole(state, action: PayloadAction<{ role: string }>) {
      //@ts-ignore
      state.user.userRole = action.payload.role;
    },

    /**
     * Dispatch resetUser to reset the redux store. Handy when clearing user sessions when logging out.
     */
    resetUser(state) {
      state.user = null;
      state.authToken = null;
    },
    setAuthToken(state, action: PayloadAction<{ authToken: string }>) {
      const { authToken } = action.payload;
      state.authToken = authToken;
    },
  },
  extraReducers: (builder) => {
    //Cases for updateUser
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = {
        ...action.payload,
        id: state.user?.id as string,
      };
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.loading = false;
    });
    //Cases for getUserData
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.user = {
        ...action.payload,
        id: state.user?.id as string,
      };
      state.loading = false;
    });
    builder.addCase(getUserData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default userSlice.reducer;

export const { setUser, resetUser, setAuthToken, changeUserRole } =
  userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;

export const getAuthToken = (state: RootState) => state.user.authToken;

export const isLoading = (state: RootState) => {
  return (
    state.userData.loading ||
    state.campaign.loading ||
    state.client.loading ||
    state.user.loading
  );
};
