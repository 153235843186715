import { Outlet } from "react-router-dom";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Header from "./Header";
import { useTheme } from "@mui/material";
import CustomContainer from "src/components/CustomContainer";

function SidebarLayout() {
  const theme = useTheme();
  return (
    <>
      {/* <AppBar
        sx={{ background: `${theme.colors.alpha.black[100]}` }}
        position="static"
      >
        <Container maxWidth="lg"></Container>
      </AppBar> */}
      <AppBar
        sx={{ background: `${theme.colors.alpha.black[100]}` }}
        position="static"
      >
        <CustomContainer>
          <Header />
        </CustomContainer>
      </AppBar>
      <Outlet />
    </>
  );
}
export default SidebarLayout;
