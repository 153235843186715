import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material";

type CircularIndeterminateProps = {
  sx?: SxProps<Theme> | undefined;
  size?: number;
};

const CircularIndeterminate: React.FC<
  React.PropsWithChildren<CircularIndeterminateProps>
> = ({ sx, size }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress size={size} sx={sx} />
    </Box>
  );
};

export default CircularIndeterminate;
