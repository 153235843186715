import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  current,
} from "@reduxjs/toolkit";
import { CreateHttpClient } from "src/providers/dataProvider";
import { CLIENT, Client } from "src/types/types";
import { RootState } from "../store";
import { IClientSlice } from "./client.contract";
import firebase from "src/firebase";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import _ from "lodash";
import customDataProvider from "src/providers/customDataProvider";
const initialState: IClientSlice = {
  clients: [],
  client: null,
  temporaryClient: null,
  loading: false,
  filteredClients: [],
};

const httpClient = CreateHttpClient<Client>("Client");

export const getAllClients = createAsyncThunk(
  "clients/getAllClients",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const response = await httpClient.getAll(state.user.authToken as string);
      
      // Fetch notification counts for each client
      const clientsWithNotifications = await Promise.all(
        response.data.map(async (client) => {
          // Fetch the Firebase user ID for the client
          const userResponse = await customDataProvider.getUser(client.userId, state.user.authToken as string);
          const firebaseUserId = userResponse.data.firebaseId;

          // Query notifications for the fetched Firebase user ID
          const notificationsRef = firebase.firestore()
            .collection('notifications')
            .where('isRead', '==', false)
            .where('firebaseUserId', '==', firebaseUserId);

          const notificationsSnapshot = await notificationsRef.get();
          const notificationsCount = notificationsSnapshot.docs.length;

          // Return the updated client object with the notification count
          return { ...client, notification: notificationsCount };
        })
      );

      return clientsWithNotifications;
    } catch (error) {
      // Handle errors appropriately
      throw error;
    }
  }
);



export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.update(params, state.user.authToken as string);
  }
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.create(params, state.user.authToken as string);
  }
);

export const deleteClient = createAsyncThunk(
  "clients/deleteClient",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    await httpClient.delete(params, state.user.authToken as string);
    return params;
  }
);

export const getClientData = createAsyncThunk(
  "clients/getClientData",
  async (params: any, thunkAPI) => {
    const { id } = params;
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getOne(id, state.user.authToken as string);
  }
);

export const getUserClient = createAsyncThunk(
  "clients/getClientData",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getOne('user', state.user.authToken as string, params);
  }
);

const clientDataSlice = createSlice({
  name: "clientData",
  initialState,
  reducers: {
    setSelectedClient(state, action: PayloadAction<{ client: any }>) {
      state.client = action.payload.client;
    },

    setTemporarySelectedClient(state, action: PayloadAction<{ client: any }>) {
      state.temporaryClient = action.payload.client;
    },
  },
  extraReducers: (builder) => {
    //Cases for getAllClients
    builder.addCase(getAllClients.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllClients.fulfilled, (state, action) => {
      state.clients = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllClients.rejected, (state) => {
      state.loading = false;
    });

    //Cases for updateClient
    builder.addCase(updateClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.client = action.payload.data;
      state.clients = state.clients.map((obj) => {
        if (obj.id === action.payload.data.id) {
          return action.payload;
        }
        return obj;
      });
      state.loading = false;
    });
    builder.addCase(updateClient.rejected, (state) => {
      state.loading = false;
    });


    //Cases for updateClient
    builder.addCase(getUserClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserClient.fulfilled, (state, action) => {
      state.client = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getUserClient.rejected, (state) => {
      state.loading = false;
    });



    // Cases for createClient
    builder.addCase(createClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createClient.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.client = data;

      state.clients.push(data);
      state.loading = false;
    });
    builder.addCase(createClient.rejected, (state) => {
      state.loading = false;
    });

    // Cases for deleteClient
    builder.addCase(deleteClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteClient.fulfilled, (state, action) => {
      // state.client = null;
      state.clients = state.clients.filter((obj) => obj.id !== action.payload);
      state.loading = false;
    });
    builder.addCase(deleteClient.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default clientDataSlice.reducer;

export const { setSelectedClient, setTemporarySelectedClient } =
  clientDataSlice.actions;

export const getClientsList = (state: RootState) => {
  return state.client.clients;
};

export const filteredClients = (state: RootState) => {
  return state.client.filteredClients;
};

export const getClient = (state: RootState) => {
  return state.client.client;
};

export const getTemporarySelectedClient = (state: RootState) => {
  return state.client.temporaryClient;
};
