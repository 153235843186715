import { Typography, Divider, Box } from "@mui/material";
import RChip from "../RChip";
import React, { PropsWithChildren, useState, useEffect } from "react";
import { UilTimes } from "@iconscout/react-unicons";
import { Notification } from "src/types/types";
import { useAppSelector } from "src/hooks/hooks";
import { isLoading, selectUser } from "src/store/user/userSlice";
import { DateTimeNow } from "src/helpers/dateHelper";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import firebase from "src/firebase";
import { toast } from "react-toastify";


type NotificationsModalProps = {
  anchor: string;
  toggleDrawer: (
    anchor: string,
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const NotificationsModal: React.FC<
  PropsWithChildren<NotificationsModalProps>
> = ({ anchor, toggleDrawer }) => {

  const loading = useAppSelector(isLoading);
  const user = useAppSelector(selectUser);

  let notificationsRef = firebase.firestore()
    .collection('notifications')
    .where('isRead', '==', false)
    .where('firebaseUserId', '==', user?.firebaseId);

  const [notifications] = useCollectionData(notificationsRef);

  const calculateUnread = () => {
    let unreadCount = 0;
    if (notifications) {
      for (const notification of notifications) {
        if (notification.isRead === false) {
          unreadCount++;
        }
      }
    }

    return unreadCount + " new notifications";
  };
  const clearNotifications = () => {
    notificationsRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.update({
          readDateTime: DateTimeNow(),
          isRead: true
        });
      });
    })
      .catch((error) => {
        toast.error(`Error getting documents: ${error}`);
      });

  };

  const deleteSelectedNotifications = async (
    notificationId: string
  ) => {
    notificationsRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().id === notificationId) {
          doc.ref.update({
            readDateTime: DateTimeNow(),
            isRead: true
          });
        }
      });
    })
      .catch((error) => {
        toast.error(`Error getting documents: ${error}`);
      });

  };

  return (
    <Box
      role="presentation"
      onClick={() => toggleDrawer(anchor, false)}
      onKeyDown={() => toggleDrawer(anchor, false)}
      sx={{ padding: 2 }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6Bold">Activity</Typography>
        <RChip
          sx={{
            fontWeight: 700,
            height: 25,
            padding: 1,
            backgroundColor: "#000",
            color: "#fff",
          }}
          label={calculateUnread()}
        />
        <Typography
          variant="smallerBold"
          color="black"
          marginTop={0.7}
          sx={{ cursor: "pointer" }}
          onClick={clearNotifications}
        >
          Clear
        </Typography>
      </Box>
      {!!notifications && notifications.length > 0 ? notifications?.map((notification, index) => (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 1,
            }}
            key={index}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <Typography variant="small" color="#000">
                {notification.title}
              </Typography>
              {/* <Typography variant="smaller" color="#808080"> */}
              {/* Jul 23 2022 at 09:15AM */}
              {/* {notification.creationTime} */}
              {/* </Typography> */}
              <Typography sx={{ fontSize: 9, marginTop: 1 }}>
                {notification.description}
              </Typography>
            </Box>
            <Box
              sx={{ marginTop: 4, color: "#808080" }}
              onClick={() =>
                deleteSelectedNotifications(notification.id)
              }
            >
              <UilTimes size={22} />
            </Box>
          </Box>
          <Divider sx={{ marginTop: 0.4 }} />
        </>
      )) :
        <>
          {/* <img src={hatchMessage} alt="loading" className={classes.done} /> */}
          <Typography >you don't have any notifications yet 😔</Typography>
        </>
      }
    </Box>
  );
};

export default NotificationsModal;
