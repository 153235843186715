import { display } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { RButton, RInput } from "src/components";
import { Formik, Form } from "formik";
import { typeVar } from "src/components/RButton";
import pages from "src/router/routes";

const PasswordReset = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundImage: `url(../forgotPassword.svg)`,
        backgroundSize: "cover",
        height: "100vh",
        color: "#f5f5f5",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          width: "526px",
          height: "572px",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            height: "85px",
            width: "85px",
            top: "154px",
            position: "absolute",
          }}
        >
          <img src="/static/images/app/greenTick.svg" alt="success" />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "40px",
              lineHeight: "49px",
              color: "#000",
              textAlign: "center",
            }}
          >
            Password Reset
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "140%",
              color: "#000",
              top: "384px",
              textAlign: "center",
              marginTop: "10px",
              padding: "20px",
            }}
          >
            Your password has been successfully reset. Click below to log in
            magically.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
            mx: "auto",
            width: "70%",
          }}
        >
          <RButton
            typeVar={typeVar.Contained}
            name="Continue"
            isSubmitting={true}
            onEvent={() => navigate(pages.auth.login.path)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PasswordReset;
