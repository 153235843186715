import { display } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { RButton, RInput } from "src/components";
import { Formik, Form } from "formik";
import { typeVar } from "src/components/RButton";
import pages from "src/router/routes";

const CheckEmail = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundImage: `url(../forgotPassword.svg)`,
        backgroundSize: "cover",
        height: "100vh",
        color: "#f5f5f5",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          width: "526px",
          height: "572px",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            height: "85px",
            width: "85px",
            top: "154px",
            position: "absolute",
          }}
        >
          <img src="/static/images/app/checkEmail.svg" alt="key" />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "40px",
              lineHeight: "49px",
              color: "#000",
            }}
          >
            Check your email
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "140%",
              color: "#000",
              top: "384px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            We sent a password reset code to
            <b> email</b>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
            mx: "auto",
          }}
        >
          <RButton
            typeVar={typeVar.Contained}
            name="Open email app"
            isSubmitting={true}
            onEvent={() => {
              window.location.href = "mailto:";
            }}
          />
          <Box
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              height: "24px",
              paddingTop: "25px",
              color: "#000",
            }}
          >
            <Typography sx={{ textAlign: "center", lineHeight: "40px" }}>
              Didn't receive an email?{" "}
              <b onClick={() => navigate(pages.auth.forgotPassword.path)}>
                Click to resend
              </b>
            </Typography>
            <Typography sx={{ textAlign: "center", lineHeight: "40px" }}>
              <b onClick={() => navigate(pages.auth.login.path)}>
                Back to login
              </b>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckEmail;
