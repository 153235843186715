import React, { PropsWithChildren } from "react";
import { Paper, styled } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow:
    " 0px -5px 8px 1px rgba(0, 0, 0, 0.03), 0px 35px 35px rgba(0, 0, 0, 0.08), 0px 4px 5px rgba(0, 0, 0, 0.05)",
  borderRadius: "15px",
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

interface GridItemProps {
  color: string;
}
const GridItem: React.FC<PropsWithChildren<GridItemProps>> = ({
  children,
  color,
}) => {
  return <Item sx={{ backgroundColor: color }}>{children} </Item>;
};

const RCard: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Item></Item>;
};
export default RCard;
