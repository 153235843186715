import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

let authUrl = "https://localhost:7153/api/1";
if (process.env.REACT_APP_ENV == "Production") {
  authUrl = "https://marketing-platform-prod.azurewebsites.net/api/1";
} else if (process.env.REACT_APP_ENV == "Staging") {
  //TODO: We need to point this to staging
  authUrl = "https://marketing-platform-api.azurewebsites.net/api/1";
}
export default {
  login: async (username: string, password: string) => {
    try {
      const url = `${authUrl}/Authentication/Login`;

      const response = await axios.post(
        url,
        { email: username, password },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status === 200) {
        const data = response.data;
        return Promise.resolve({ success: true, data: data.data });
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    } catch (error: any) {
      return Promise.reject(new Error(error.message));
    }
  },
  signup: async (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => {
    try {
      const url = `${authUrl}/Authentication/Signup`;

      const response = await axios.post(
        url,
        { firstName, lastName, email, password },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status === 200) {
        return Promise.resolve({ success: true });
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    } catch (error: any) {
      return Promise.reject(new Error(error.message));
    }
  },
  validateEmail: async (token: string, newPassword: string) => {
    try {
      const url = `${authUrl}/Authentication/Verify`;
      const response = await axios.post(
        url,
        { token, newPassword },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );


      if (response.status === 200) {
        toast.success(response.data.message);

        return Promise.resolve({ success: true });
      } else {
        toast.warning(response.data.message);

        return Promise.reject(new Error(response.statusText));
      }
    } catch (error: any) {
      toast.error(error.message);

      return Promise.reject(new Error(error.message));
    }
  },
};
