import { Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ChangingProgressProvider: React.FC = () => {
  let values = [0, 10, 20, 30, 40, 50, 60, 80, 100];

  let defaultProps = {
    interval: 1000,
  };

  const [valuesIndex, setValuesindex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      let answer = (valuesIndex + 1) % values.length;
      setValuesindex(answer);
    }, defaultProps.interval);
    // eslint-disable-next-line
  }, [valuesIndex]);

  return (
    <CircularProgressbarWithChildren
      value={values[valuesIndex]}
      styles={{
        root: {},

        path: {
          background: `colors.primary`,

          strokeLinecap: "butt",

          transition: "stroke-dashoffset 0.5s ease 0s",

          transform: "rotate(0.0turn)",
          transformOrigin: "center center",
        },

        trail: {
          stroke: "#d6d6d6",

          strokeLinecap: "butt",

          transform: "rotate(0.25turn)",
          transformOrigin: "center center",
        },

        text: {
          fill: "#f88",

          fontSize: "10px",
        },

        background: {
          fill: "#3e98c7",
        },
      }}
    >
      <Typography>Loading...</Typography>
      <Typography sx={{}}>{values[valuesIndex]}%</Typography>
    </CircularProgressbarWithChildren>
  );
};

export default ChangingProgressProvider;
