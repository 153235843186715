import { IconButton } from "@mui/material";
import React from "react";
import { UilInbox } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";
import pages from "src/router/routes";

function Inbox() {
  const navigate = useNavigate();
  return (
    <>
      <IconButton color="primary">
        <UilInbox onClick={() => navigate(pages.revx.inbox.path)} />
      </IconButton>
    </>
  );
}

export default Inbox;
