import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

type EmptyStateProps={
  imageUrl:string;
  heading:string;
  subHeading:string

}

const EmptyState:React.FC<PropsWithChildren<EmptyStateProps>> = ({imageUrl,heading,subHeading}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <img src={imageUrl} alt="empty" height={250} />
      <Typography variant="h6Bold" sx={{ textAlign: "center" }}>
     {heading}
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          width: 400,
          color: "rgba(155, 155, 155, 1)",
          marginLeft:45
        }}
      >
       {subHeading}
      </Typography>
    </Box>
  );
};

export default EmptyState 
