import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ICampaignSlice } from "./campaignData.contracts";
import { CreateHttpClient } from "src/providers/dataProvider";
import { Campaign, FaceBookCampaign, GOOGLE, GoogleCampaign } from "src/types/types";
import customDataProvider from "src/providers/customDataProvider";

const initialState: ICampaignSlice = {
  campaigns: [],
  assignedCampaigns: [],
  loading: false,
  campaignsToAssign: [],
  campaign: null,
  pages: []
};

const httpClient = CreateHttpClient<Campaign>("Campaign");

export const getAllCampaigns = createAsyncThunk(
  "campaigns/getAllCampaigns",
  async (_params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getAll(state.user.authToken as string);
  }
);

export const getCampaign = createAsyncThunk(
  "campaigns/getCampaign",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getOne(params, state.user.authToken as string);
  }
);

export const getFacebookPages = createAsyncThunk(
  "campaigns/getFacebookPages",
  async (_params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await customDataProvider.getFacebookPages(state.user.authToken as string);
  }
);

export const getAssignedCampaigns = createAsyncThunk(
  "campaigns/getAssignedCampaigns",
  async (params: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getAll(state.user.authToken as string, params);
  }
);

export const getAssignedCampaignsClient = createAsyncThunk(
  "campaigns/getAssignedCampaignsClient",
  async (params: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getAll(
      state.user.authToken as string,
      undefined,
      undefined,
      params
    );
  }
);

export const getAssignableCampaigns = createAsyncThunk(
  "campaigns/getAssignableCampaigns",
  async (params: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getAll(
      state.user.authToken as string,
      params,
      "getAssignableCampaigns"
    );
  }
);

export const assignCampaigns = createAsyncThunk(
  "campaigns/assignCampaigns",
  async (params: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    let assignedCampaignsToUpdate: Campaign[] = [];
    state.campaign.assignedCampaigns.forEach((obj: Campaign) => {
      assignedCampaignsToUpdate.push({ ...obj, userId: params });
    });

    return await httpClient.updateAll(
      assignedCampaignsToUpdate,
      state.user.authToken as string
    );
  }
);

export const unAssignCampaign = createAsyncThunk(
  "campaigns/unAssignCampaigns",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.update(
      { ...params, userId: null },
      state.user.authToken as string
    );
  }
);

export const createCampaign = createAsyncThunk(
  "campaigns/createCampaign",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.create(
      { ...params },
      state.user.authToken as string
    );
  }
);

export const updateCampaign = createAsyncThunk(
  "campaigns/updateCampaign",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.update(
      { ...params },
      state.user.authToken as string
    );
  }
);

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    //This function is used to manage the local data when selecting and deselcting campaings for assignment by the admin
    selectAssignCampaign(state, action: PayloadAction<{ campaign: Campaign }>) {
      state.assignedCampaigns.push(action.payload.campaign);

      return;
    },
    //This function is used to manage the local data when selecting and deselcting campaings for assignment by the admin
    deselectAssignCampaign(
      state,
      action: PayloadAction<{ campaign: Campaign }>
    ) {
      state.assignedCampaigns = state.assignedCampaigns.filter((camp) => {
        return camp.id !== action.payload.campaign.id;
      });
    },
    // Sets the selected campaign to be used by various modals and editing the campaign
    setSelectedCampaign(
      state,
      action: PayloadAction<{ campaign: Campaign | null }>
    ) {
      state.campaign = action.payload.campaign;
    },

    setCampaignDocument(
      state,
      action: PayloadAction<{
        campaign: FaceBookCampaign | GoogleCampaign | Campaign;
      }>
    ) {
      state.campaign = action.payload.campaign;
    },
    // This ensures that the data is clean for if the user goes to another screen that uses the campaigns array
    resetCampaigns(state) {
      state.campaigns = [];
    },
    // This ensures that the data is clean for if the user goes to another screen that uses the campaigns array
    resetCampaign(state) {
      state.campaign = null;
    }
  },
  extraReducers: (builder) => {
    // Gets all campaigns currently assigned to the user
    builder.addCase(getAssignedCampaigns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssignedCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getAssignedCampaigns.rejected, (state) => {
      state.loading = false;
    });
    // Gets all campaigns currently assigned to the user
    builder.addCase(getAssignedCampaignsClient.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssignedCampaignsClient.fulfilled, (state, action) => {
      state.campaigns = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getAssignedCampaignsClient.rejected, (state) => {
      state.loading = false;
    });
    // Gets aa single campaign document
    builder.addCase(getCampaign.pending, (state) => {
      state.loading = true;
      state.campaign = null;
    });
    builder.addCase(getCampaign.fulfilled, (state, action) => {
      state.campaign = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getCampaign.rejected, (state) => {
      state.loading = false;
    });
    // Gets aa single campaign document
    builder.addCase(getFacebookPages.pending, (state) => {
      state.loading = true;
      state.pages = [];
    });
    builder.addCase(getFacebookPages.fulfilled, (state, action) => {
      state.pages = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getFacebookPages.rejected, (state) => {
      state.loading = false;
    });
    //Gets all the campagins that can be assigned to the user
    builder.addCase(getAssignableCampaigns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAssignableCampaigns.fulfilled, (state, action) => {
      state.campaignsToAssign = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getAssignableCampaigns.rejected, (state) => {
      state.loading = false;
    });
    //Gets all the campagins that can be assigned to the user
    builder.addCase(getAllCampaigns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getAllCampaigns.rejected, (state) => {
      state.loading = false;
    });

    //Assigns the campaigns to a user
    builder.addCase(assignCampaigns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(assignCampaigns.fulfilled, (state) => {
      state.assignedCampaigns = [];
      state.loading = false;
    });
    builder.addCase(assignCampaigns.rejected, (state) => {
      state.loading = false;
    });

    //Unassigns the campaigns from a user
    builder.addCase(unAssignCampaign.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(unAssignCampaign.fulfilled, (state) => {
      state.assignedCampaigns = [];
      state.loading = false;
    });
    builder.addCase(unAssignCampaign.rejected, (state) => {
      state.loading = false;
    });
    //Create campaign cases
    builder.addCase(createCampaign.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      state.campaign = action.payload.data.data;
      state.loading = false;
    });
    builder.addCase(createCampaign.rejected, (state) => {
      state.loading = false;
    });
    //Update campaign cases
    builder.addCase(updateCampaign.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCampaign.fulfilled, (state) => {
      state.campaign = null;
      state.loading = false;
    });
    builder.addCase(updateCampaign.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default campaignSlice.reducer;

export const {
  setSelectedCampaign,
  selectAssignCampaign,
  deselectAssignCampaign,
  resetCampaigns,
  setCampaignDocument,
  resetCampaign,
} = campaignSlice.actions;

export const selectUserCampaigns = (state: RootState) => {
  return state.campaign.campaigns;
};

// This function is used in the assigning and unassigning of campaigs, so just the local values
export const selectAssignableCampaigns = (state: RootState) => {
  return state.campaign.campaignsToAssign;
};

// This function is used in the assigning and unassigning of campaigs, so just the local values
export const selectAssignedCampaigns = (state: RootState) => {
  return state.campaign.assignedCampaigns;
};

export const getSelectedCampaign = (state: RootState) => {
  return state.campaign.campaign;
};

export const getPages = (state: RootState) => {
  return state.campaign.pages;
};
