import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { RButton, RInput } from "src/components";
import { typeVar } from "src/components/RButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { display } from "@mui/system";
import CheckEmail from "./CheckEmail";
import { useState } from "react";
import routes from "src/router/router";
import pages from "src/router/routes";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });
  const initialValues = {
    email: "",
  };

  const handleClick = () => {
    navigate(pages.auth.checkEmail.path);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(../forgotPassword.svg)`,
        backgroundSize: "cover",
        height: "100vh",
        color: "#f5f5f5",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          width: "526px",
          height: "572px",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            height: "85px",
            width: "85px",
            top: "154px",
            position: "absolute",
          }}
        >
          <img src="/static/images/app/passwordKey.svg" alt="key" />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "40px",
              lineHeight: "49px",
              color: "#000",
              top: "384px",
            }}
          >
            Forgot Password?
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "140%",
              color: "#000",
              top: "384px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            Don't worry, we'll help you reset it.
          </Typography>
        </Box>
        <Box sx={{ width: "70%", marginTop: "8px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {}}
          >
            {({ isSubmitting, errors }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                }}
              >
                <RInput
                  label="Email Address"
                  placeholder="Enter your email address"
                  name="email"
                  type="text"
                />
                <RButton
                  typeVar={typeVar.Contained}
                  name="Reset Password"
                  isSubmitting={isSubmitting}
                  onEvent={() => navigate(pages.auth.checkEmail.path)}
                />
              </Form>
            )}
          </Formik>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              height: "24px",
              paddingTop: "25px",
              color: "#000",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>
              <b onClick={() => navigate(pages.auth.login.path)}>
                Back to login
              </b>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
