import {
  Box,
  Container,
  Divider,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
} from "@mui/material";
import {
  UilArrowLeft,
  UilCalendarAlt,
  UilAngleDown,
  UilSearch,
} from "@iconscout/react-unicons";
import { RButton, RChip } from "src/components";
import Status, { statusEnum } from "../../components/Status";
import { typeVar } from "src/components/RButton";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { StyledMenu } from "src/layouts/SearchBarComponent";
import GridItem from "src/components/GridItem";

const Campaigns = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const opened = Boolean(anchorEl);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ backgroundColor: "#fff", }}>
      <Container>
        <Box sx={{ display: "flex", marginY: 2 }}>
          <UilArrowLeft />
          <Typography>Back</Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h6Bold">Q3 Market Launch</Typography>
          <Box
            sx={{
              display: "flex",
              marginX: 10,
              backgroundColor: "#F3F3F3",
              paddingX: 1,
              paddingTop: 0.2,
              borderRadius: "10px",
              height: "30px",
            }}
          >
            <UilCalendarAlt />
            <Typography>10 July - 20 October 2022</Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Status name="Active" digitCount={2} />
            <Status name="Pending" digitCount={2} />
          </Box>
          <Box sx={{ marginLeft: 20, marginTop: -1 }}>
            <RButton
              name="Sort"
              typeVar={typeVar.OutlinedBlack}
              endIcon={<UilAngleDown />}
              //@ts-ignore
              onEvent={handleClick}
              height={40}
            />
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={opened}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleCloseMenu} disableRipple>
                Status
              </MenuItem>
              <MenuItem onClick={handleCloseMenu} disableRipple>
                Name
              </MenuItem>

              <MenuItem onClick={handleCloseMenu} disableRipple>
                A to Z
              </MenuItem>
              <MenuItem onClick={handleCloseMenu} disableRipple>
                Z to A
              </MenuItem>
            </StyledMenu>
          </Box>
        </Box>
        <Divider
          sx={{ marginY: 2, backgroundColor: "rgba(243, 243, 243, 1)" }}
        />
        <Box sx={{ display: "flex", marginTop: 4 }}>
          <OutlinedInput
            id="outlined-adornment-weight"
            startAdornment={
              <InputAdornment position="end">
                <UilSearch />
              </InputAdornment>
            }
            sx={{
              height: 35,
              width: 1100,
              marginRight: 2,
              borderRadius: "13px",
              color: "#000 !important",
              "& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined": {
                color: "#000 !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#E5E4E2",
                color: "#000 !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
                color: "#000",
              },
              "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
                color: "#000",
              },

              "&.MuiInputbase-input": {
                color: "#000",
              },
            }}
            placeholder={"Search for a campaign"}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
          <RButton name="Search" height={35} typeVar={typeVar.OutlinedBlack} />
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <GridItem color="#fff">
                <Box
                  sx={{
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Status name="Pending" />
                </Box>
                <Box
                  sx={{
                    paddingLeft: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h6Bold">Spring Launch 2022</Typography>
                  <Typography
                    variant="small"
                    sx={{ color: "#9B9B9B", marginY: 1 }}
                  >
                    This campaign is to market for the upcoming spring launch...
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#F3F3F3",
                      padding: 0.5,
                      borderRadius: "8px",
                      marginY: 1,
                    }}
                  >
                    <UilCalendarAlt />
                    <Typography>10 July - 20 October 2022</Typography>
                  </Box>
                  <Box>
                    <RChip
                      label="Instagram"
                      sx={{
                        fontWeight: 700,
                        height: 25,
                        padding: 1,
                        backgroundColor: "#000",
                        color: "#FFF",
                      }}
                    />
                  </Box>
                </Box>
              </GridItem>
            </Grid>
            <Grid item xs={3}>
              <GridItem color="#fff">
                <Box
                  sx={{
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Status name="Pending" />
                </Box>
                <Box
                  sx={{
                    paddingLeft: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h6Bold">Spring Launch 2022</Typography>
                  <Typography
                    variant="small"
                    sx={{ color: "#9B9B9B", marginY: 1 }}
                  >
                    This campaign is to market for the upcoming spring launch...
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#F3F3F3",
                      padding: 0.5,
                      borderRadius: "8px",
                      marginY: 1,
                    }}
                  >
                    <UilCalendarAlt />
                    <Typography>10 July - 20 October 2022</Typography>
                  </Box>
                  <Box>
                    <RChip
                      label="Instagram"
                      sx={{
                        fontWeight: 700,
                        height: 25,
                        padding: 1,
                        backgroundColor: "#000",
                        color: "#FFF",
                      }}
                    />
                  </Box>
                </Box>
              </GridItem>
            </Grid>
            <Grid item xs={3}>
              <GridItem color={"#fff"}>
                <Box
                  sx={{
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Status name="Pending"  />
                </Box>
                <Box
                  sx={{
                    paddingLeft: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h6Bold">Spring Launch 2022</Typography>
                  <Typography
                    variant="small"
                    sx={{ color: "#9B9B9B", marginY: 1 }}
                  >
                    This campaign is to market for the upcoming spring launch...
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#F3F3F3",
                      padding: 0.5,
                      borderRadius: "8px",
                      marginY: 1,
                    }}
                  >
                    <UilCalendarAlt />
                    <Typography>10 July - 20 October 2022</Typography>
                  </Box>
                  <Box>
                    <RChip
                      label="Instagram"
                      sx={{
                        fontWeight: 700,
                        height: 25,
                        padding: 1,
                        backgroundColor: "#000",
                        color: "#FFF",
                      }}
                    />
                  </Box>
                </Box>
              </GridItem>
            </Grid>
            <Grid item xs={3}>
              <GridItem color={"#fff"}>
                <Box
                  sx={{
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Status name="Pending" />
                </Box>
                <Box
                  sx={{
                    paddingLeft: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h6Bold">Spring Launch 2022</Typography>
                  <Typography
                    variant="small"
                    sx={{ color: "#9B9B9B", marginY: 1 }}
                  >
                    This campaign is to market for the upcoming spring launch...
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#F3F3F3",
                      padding: 0.5,
                      borderRadius: "8px",
                      marginY: 1,
                    }}
                  >
                    <UilCalendarAlt />
                    <Typography>10 July - 20 October 2022</Typography>
                  </Box>
                  <Box>
                    <RChip
                      label="Instagram"
                      sx={{
                        fontWeight: 700,
                        height: 25,
                        padding: 1,
                        backgroundColor: "#000",
                        color: "#FFF",
                      }}
                    />
                  </Box>
                </Box>
              </GridItem>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Campaigns;
