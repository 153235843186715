import { Box, Container } from "@mui/material";
import React, { PropsWithChildren } from "react";

const CustomContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container sx={{ maxWidth: "86%" }} maxWidth={false}>
      {children}
    </Container>
  );
};

export default CustomContainer;
