import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import { FormLabel, Typography } from "@mui/material";
import { FieldProps } from "formik";
import { ROLENAMEMAPPER } from "src/types/types";

interface ISelect {
  label: string;
  value: string;
}
interface RSelectProps extends FieldProps {
  data: ISelect[];
  placeholder: string;
  onSelect: (value: any) => void;
  label: string;
  userRole: string;
}

const RSelect: React.FC<React.PropsWithChildren<RSelectProps>> = ({
  data,
  userRole,
  form,
  onSelect,
  field,
  label,
  placeholder
}) => {
  return (
    <FormControl sx={{ mt: 2, width: 230, marginBottom: 2 }}>
      <FormLabel htmlFor="name">
        <Typography variant="smallBold">{label}</Typography>
      </FormLabel>
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={field.value}
        // placeholder={label === "User Role" ?  ROLENAMEMAPPER[field.value] : field.value}
        options={data}
        onChange={(e: { label: string; value: string }) => {
          form.setFieldValue(field.name, e.value);
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        // value={field.value}
      />
    </FormControl>
  );
};

export default RSelect;
