import * as React from "react";
import Chip from "@mui/material/Chip";
import { SxProps } from "@mui/system";

interface RChipProps {
  label: string;
  icon?: any;
  avatar?: any;
  sx?: SxProps;
}

const RChip: React.FC<React.PropsWithChildren<RChipProps>> = ({
  label,
  icon,
  avatar,
  sx,
}) => {
  return (
    <>
      <Chip sx={sx} label={label} icon={icon} avatar={avatar} />
    </>
  );
};
export default RChip;
