import React from "react";
import { Box, Grid } from "@mui/material";
import _ from "lodash";
import EmptyState from "src/components/EmptyStates";
import { Campaign } from "src/types/types";
import CampaignCard from "src/components/CampaignCard";

const PastGroupCampaings: React.FC = () => {
  const pastGroupCamapigns: Campaign[] = [];

  return (
    <Box sx={{ flexGrow: 1, marginTop: 1 }}>
      {_.isEmpty(pastGroupCamapigns) ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <EmptyState
            imageUrl="/static/images/app/activeEmpty.svg"
            heading="No group campaigns yet"
            subHeading="Create and start a new campaign to track your marketing progress."
          />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {pastGroupCamapigns.map((pastGroupCamapign, index) => (
            <CampaignCard
              campaignTypeId={pastGroupCamapign.campaignTypeId}
              isMonitor={false}
              key={index}
              socialMediaLabel=""
              status={pastGroupCamapign.status}
              campaignHeader={pastGroupCamapign.title}
              campaignDuration={pastGroupCamapign.duration}
              campaignSubHeader={pastGroupCamapign.description}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};
export default PastGroupCampaings;
