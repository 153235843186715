import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "src/contexts/UserContext";
import { useAppSelector } from "src/hooks/hooks";
import { getAuthToken } from "src/store/user/userSlice";
import pages from "./routes";

interface AuthGuardProps {
  children: JSX.Element;
  roles: string[];
}

const AuthAndRoleGuard: React.FC<AuthGuardProps> = (props) => {
  const { children, roles } = props;
  const { user } = useAuthContext();
  const token = useAppSelector(getAuthToken);
  //TODO: Does this code possibly need to be added into a useEffect, it doesn't seem like it's triggering often enough at the moment
  const navigate = useNavigate();

  // const userHasRequiredRole = user && roles.includes(user.role) ? true : false;

  // if (isAuthenticated && !userHasRequiredRole) {
  //   return <Forbidden403 />;
  // }

  useEffect(() => {
    const isAuthenticated = token !== null;
    if (!isAuthenticated) {
      navigate(pages.auth.login.path);
    }
  }, [token]);

  return children;
};

export default AuthAndRoleGuard;
