import { useEffect } from "react";

import { Box } from "@mui/material";

function SuspenseLoader() {
  return (
    <Box>
      <img
        src="/static/images/app/clientDashboardLoader.webp"
        alt="loader"
        width={"100%"}
      />
    </Box>
  );
}

export default SuspenseLoader;
