import React from "react";

import { Theme } from "@mui/material";

import { NebulaFighterTheme } from "./schemes/NebulaFighterTheme";

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}

declare module "@mui/material/styles" {
  interface Theme {
    colors: {
      gradients: {
        textInputGradient: string;
        buttonGradient: string;
        selectGradient: string;
        datePickerGradient: string;
        checkboxGradient: string;
        progressGradient: string;
      };
      shadows: {
        success: string;
        error: string;
        primary: string;
        warning: string;
        info: string;
      };
      alpha: {
        white: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        grey: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        black: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
      };
      secondary1: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      secondary2: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      secondary3: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary1: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary2: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary3: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      success: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      error: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      info: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
    };
    general: {
      reactFrameworkColor: React.CSSProperties["color"];
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
      borderRadiusXl: string;
    };
    header: {
      height: string;
      background: React.CSSProperties["color"];
      boxShadow: React.CSSProperties["color"];
      textColor: React.CSSProperties["color"];
    };
  }

  interface ThemeOptions {
    colors: {
      gradients: {
        textInputGradient: string;
        buttonGradient: string;
        selectGradient: string;
        datePickerGradient: string;
        checkboxGradient: string;
        progressGradient: string;
      };
      shadows: {
        success: string;
        error: string;
        primary: string;
        warning: string;
        info: string;
      };
      alpha: {
        white: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        grey: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        black: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
      };
      secondary1: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      secondary2: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      secondary3: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary1: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary2: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary3: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      success: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      error: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      info: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
    };

    general: {
      reactFrameworkColor: React.CSSProperties["color"];
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
      borderRadiusXl: string;
    };
    header: {
      height: string;
      background: React.CSSProperties["color"];
      boxShadow: React.CSSProperties["color"];
      textColor: React.CSSProperties["color"];
    };
  }

  interface TypographyVariants {
    h1Bold: React.CSSProperties;
    h2Bold: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h5Bold: React.CSSProperties;
    h6Bold: React.CSSProperties;
    body: React.CSSProperties;
    semiBody: React.CSSProperties;
    bold: React.CSSProperties;
    small: React.CSSProperties;
    smallBold: React.CSSProperties;
    smaller: React.CSSProperties;
    smallerBold: React.CSSProperties;
    smallButton: React.CSSProperties;
    link?: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    h1Bold?: React.CSSProperties;
    h2Bold?: React.CSSProperties;
    h3Bold?: React.CSSProperties;
    h4Bold?: React.CSSProperties;
    h5Bold?: React.CSSProperties;
    h6Bold?: React.CSSProperties;
    body?: React.CSSProperties;
    semiBody?: React.CSSProperties;
    bold?: React.CSSProperties;
    small?: React.CSSProperties;
    smallBold?: React.CSSProperties;
    smaller?: React.CSSProperties;
    smallerBold?: React.CSSProperties;
    smallButton?: React.CSSProperties;
    link?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1Bold: true;
    h2Bold: true;
    h3Bold: true;
    h4Bold: true;
    h5Bold: true;
    h6Bold: true;
    body: true;
    semiBody: true;
    bold: true;
    small: true;
    smallBold: true;
    smaller: true;
    smallerBold: true;
    smallButton: true;
    link: true;
  }
}

const themeMap: { [key: string]: Theme } = {
  NebulaFighterTheme,
};
