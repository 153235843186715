import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/hooks/hooks";
import pages from "src/router/routes";
import { selectUser } from "src/store/user/userSlice";
import { ROLENAMEMAPPER } from "src/types/types";

function User() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(pages.revx.profile.path);
  };

  const userObject = useAppSelector(selectUser);


  return (
    <Box display="flex" onClick={handleClick} sx={{ cursor: "pointer" }}>
      {/* <img
        src="/logo-social.png"
        alt="user-Profile"
        width="40px"
        height="40px"
        style={{
          borderRadius: "50%",
        }}
      /> */}
      <Box sx={{ display: "flex", flexDirection: "column", marginLeft: 1 }}>
        <Typography sx={{ color: "#fff" }}>{userObject?.firstName} {userObject?.lastName}</Typography>
        <Typography
          variant="small"
          sx={{ color: "#808080!important", fontSize: 12 }}
        >
          {userObject ? ROLENAMEMAPPER[userObject.userRole ?? ''] : ''}
        </Typography>
      </Box>
    </Box>
  );
}

export default User;
