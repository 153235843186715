import { Box, Typography, useScrollTrigger } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RButton, RInput } from "src/components";
import { typeVar } from "src/components/RButton";
import pages from "src/router/routes";
import authProvider from "../../providers/authProvider";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const ValidateEmail = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: ""
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
      )
      .notOneOf(
        ["password", "123456", "qwerty"],
        "Password must not be a commonly used password"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const onSubmit = (values: any, { setSubmitting }: FormikHelpers<any>) => {
    setLoading(true);
    const pathSegments = window.location.pathname.split("/");
    const token = pathSegments[pathSegments.length - 1];

    authProvider
      .validateEmail(token, values.password)
      .then((result) => {
        if (result.success) {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
        setLoading(false);
        setSubmitting(false);
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
        setSubmitting(false);
      });
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(../forgotPassword.svg)`,
        backgroundSize: "cover",
        height: "100vh",
        color: "#f5f5f5",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          width: "526px",
          height: "572px",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            height: "85px",
            width: "85px",
            top: "154px",
            position: "absolute",
          }}
        >
          <img src="/static/images/app/checkEmail.svg" alt="key" />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "49px",
              color: "#000",
            }}
          >
            {(success && !loading) && "Email verified and password set!"}
            {(success && loading) && "Verifying email and setting new password..."}
           
            
          </Typography>
          {(!success || loading) && 
              <Box>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form style={{ display: "flex", flexDirection: "column" }}>
                      <RInput
                        label="Password"
                        placeholder="Enter your password"
                        name="password"
                        type="password"
                      />
                      <RInput
                        label="Confirm Password"
                        placeholder="Confirm your password"
                        name="confirmPassword"
                        type="password"
                      />
                      <RButton
                        typeVar={typeVar.Contained}
                        name="Set password"
                        isSubmitting={isSubmitting}
                      />
                    </Form>
                  )}
                </Formik>
              </Box>}
          {success && !loading && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "140%",
                color: "#000",
                top: "384px",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              Please log in to continue
            </Typography>
          )}

          
        </Box>
        {success && !loading && (
          <RButton
            typeVar={typeVar.Contained}
            name="Log in"
            onEvent={() => navigate(pages.auth.login.path)}
          />
        )}
      </Box>
    </Box>
  );
};

export default ValidateEmail;
