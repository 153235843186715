import { Box } from "@mui/material";

const LoaderTable: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 0,
      }}
    >
      <Box>
        <img
          src="/static/images/app/clientDashboardLoader.webp"
          alt="loader"
          width={"100%"}
        />
      </Box>
    </Box>
  );
};

export default LoaderTable;
