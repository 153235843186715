/* eslint-disable */
import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCJXrGd4oAOynj5w5xH4hxl3oQsb5w29Go",
  authDomain: "revx-745b6.firebaseapp.com",
  projectId: "revx-745b6",
  storageBucket: "revx-745b6.appspot.com",
  messagingSenderId: "26274447243",
  appId: "1:26274447243:web:92bf11d1bda465602db98e",
  measurementId: "G-MYXSB4ZBKF"
};


firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.storage();
firebase.database();

export default firebase;