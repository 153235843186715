import { display } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { RButton, RInput } from "src/components";
import { Formik, Form } from "formik";
import { typeVar } from "src/components/RButton";
import * as Yup from "yup";
import pages from "src/router/routes";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    confirmPassword: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });
  const initialValues = {
    password: "",
  };

  const handleClick = () => {
    navigate(pages.auth.checkEmail.path);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(../forgotPassword.svg)`,
        backgroundSize: "cover",
        height: "100vh",
        color: "#f5f5f5",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          width: "526px",
          height: "656px",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            height: "85px",
            width: "85px",
            top: "154px",
            position: "absolute",
          }}
        >
          <img src="/static/images/app/passwordKey.svg" alt="key" />
        </Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "40px",
              color: "#000",
              textAlign: "center",
              marginTop: "15px",
            }}
          >
            Set new password
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "120%",
              color: "#000",
              textAlign: "center",
              marginTop: "10px",
              paddingLeft: "12%",
              paddingRight: "12%",
            }}
          >
            Your new password must be different to previously used passwords.
          </Typography>
        </Box>
        <Box sx={{ width: "70%", marginTop: "8px" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {}}
          >
            {({ isSubmitting, errors }) => (
              <Form style={{ display: "flex", flexDirection: "column" }}>
                <RInput
                  label="Password"
                  placeholder="Enter your password"
                  name="password"
                  type="text"
                />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "10px",
                    color: "#5D616B",
                    textAlign: "left",
                  }}
                >
                  Minimum of 1 upper case, 1 lower case, 1 symbol, 1 number,
                  minimum of 8 letters
                </Typography>
                <RInput
                  label="Confirm Password"
                  placeholder="Confirm your password"
                  name="confirmPassword"
                  type="text"
                />
                <RButton
                  typeVar={typeVar.Contained}
                  name="Reset Password"
                  isSubmitting={isSubmitting}
                  onEvent={() => navigate(pages.auth.passwordReset.path)}
                />
              </Form>
            )}
          </Formik>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              height: "24px",
              paddingTop: "25px",
              color: "#000",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>
              <b onClick={() => navigate(pages.auth.login.path)}>
                Back to login
              </b>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SetNewPassword;
