import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  current,
} from "@reduxjs/toolkit";
import { CreateHttpClient } from "src/providers/dataProvider";
import { ROLENAMEMAPPER, User } from "src/types/types";
import { RootState } from "../store";
import { IUserDataSlice } from "./userData.contracts";

const initialState: IUserDataSlice = {
  users: [],
  user: null,
  temporaryUser: null,
  loading: false,
};

const httpClient = CreateHttpClient<User>("User");

const createData = (
  client: string,
  email: string,
  userRole: string,

  status: string,
  userObject: any
) => {
  return { client, email, userRole, status, userObject, creationTime: userObject.creationTime };
};

export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (_, thunkAPI) => {
    // TODO: Get pagination params
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getAll(state.user.authToken as string);
  }
);

export const getUserDocument = createAsyncThunk(
  "users/getUserDocument",
  async (_, thunkAPI) => {
    // TODO: Get pagination params
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getAll(state.user.authToken as string);
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.update(params, state.user.authToken as string);
  }
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return await httpClient.create(params, state.user.authToken as string);
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (params: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    await httpClient.delete(params, state.user.authToken as string);
    return params;
  }
);

export const getUserData = createAsyncThunk(
  "users/getUserData",
  async (params: any, thunkAPI) => {
    const { id } = params;
    const state = thunkAPI.getState() as RootState;
    return await httpClient.getOne(id, state.user.authToken as string); // TODO fix this
  }
);

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setSelectedUser(state, action: PayloadAction<{ user: any }>) {
      state.user = action.payload.user;
    },
    setFilteredRevxUsers(
      state,
      action: PayloadAction<{ searchInput: string }>
    ) {
      const unfilteredListOfUsers = current(state).users;

      const filteredListOfUsers = unfilteredListOfUsers.filter((user) =>
        user.client
          .toLowerCase()
          .includes(action.payload.searchInput.toLowerCase())
      );

      state.users = filteredListOfUsers;
    },
    setTemporarySelectedUser(state, action: PayloadAction<{ user: any }>) {
      state.temporaryUser = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    //Cases for getAllUsers
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.loading = true;

      state.users = action.payload.data.map((item) => {
        return createData(
          `${item.firstName} ${item.lastName}`,
          item.email,
          item.userRole,
          "3 Active Campaigns", //TODO: We need to find a way to calculate this
          item
        );
      });
      state.loading = false;
    });
    builder.addCase(getAllUsers.rejected, (state) => {
      state.loading = false;
    });

    //Cases for updateUser
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.users = state.users.map((obj) => {
        if (obj.id === action.payload.data.id) {
          return action.payload;
        }
        return obj;
      });
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.loading = false;
    });

    // Cases for createUser
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.user = data;

      state.users.push(
        createData(
          `${data.firstName} ${data.lastName}`,
          data.email,
          "Campaign Manager",
          "3 Active Campaigns",
          data
        )
      );
      state.loading = false;
    });
    builder.addCase(createUser.rejected, (state) => {
      state.loading = false;
    });

    // Cases for deleteUser
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      // state.user = null;
      state.users = state.users.filter((obj) => obj.id !== action.payload);
      state.loading = false;
    });
    builder.addCase(deleteUser.rejected, (state) => {
      state.loading = false;
    });
    // Cases for getUser
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.loading = false;
    });
    builder.addCase(getUserData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default userDataSlice.reducer;

export const {
  setSelectedUser,
  setTemporarySelectedUser,
  setFilteredRevxUsers,
} = userDataSlice.actions;

export const getSelectedUser = (state: RootState) => {
  return state.userData.user;
};

export const getUsersList = (state: RootState) => {
  return state.userData.users;
};

export const getUser = (state: RootState) => {
  return state.userData.user;
};

export const getTemporarySelectedUser = (state: RootState) => {
  return state.userData.temporaryUser;
};
