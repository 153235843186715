import * as React from "react";
import { useSelector } from "react-redux";
import { useAppSelector } from "src/hooks/hooks";
import { RootState } from "src/store/store";
import { selectUser, getAuthToken } from "src/store/user/userSlice";
import { User } from "src/types/types";

type AuthProviderProps = {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  user: User;
};

const AuthContext = React.createContext<Partial<AuthProviderProps>>({});

export const useAuthContext = () => React.useContext(AuthContext);

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isAuthenticating, setIsAuthenticating] = React.useState(false);
  const token = useAppSelector(getAuthToken);
  const user = useAppSelector(selectUser);

  React.useEffect(() => {
    setIsAuthenticating(true);
    setIsAuthenticated(token !== null && user !== null);
    setIsAuthenticating(false);
  });
  
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAuthenticating,
        user: user as User
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
