import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { DateTimeNow } from "src/helpers/dateHelper";
import { useAppSelector } from "src/hooks/hooks";
import pages from "src/router/routes";

/*
 * This provider will be used to create functions that interacts with the API
 * in a way that cannot fit into the authProvider and dataProvider, e.g.
 * fetching a list of constants.
 */

let baseUrl = "https://localhost:7153/api/1";
if (process.env.REACT_APP_ENV == "Production") {
  baseUrl = "https://marketing-platform-prod.azurewebsites.net/api/1";
} else if (process.env.REACT_APP_ENV == "Staging") {
  //TODO: We need to point this to staging
  baseUrl = "https://marketing-platform-api.azurewebsites.net/api/1";
}

const handleUnauthorized = (error: AxiosError) => {
  if (error.response?.status === 401) {
    window.location.href = pages.auth.login.path;
  }
  throw error;
};

export default {
  getFacebookPages: async (authToken: string) => {
    try {
      const response = await axios
        .get(`${baseUrl}/FacebookPages`, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
        .catch(handleUnauthorized);

      if (response.status === 200) {
        const data = response.data;
        return Promise.resolve({ success: true, data: data });
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    } catch (error: any) {
      return Promise.reject(new Error(error.message));
    }
  },
  postNotification: async (
    authToken: string,
    description: string,
    title: string,
    firebaseUserId: string
  ) => {
    try {
      const response = await axios
        .post(
          `${baseUrl}/Notification`,
          {
            creationTime: DateTimeNow(),
            firebaseUserId,
            title,
            description,
          },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .catch(handleUnauthorized);

      if (response.status === 200) {
        const data = response.data;
        return Promise.resolve({ success: true, data: data });
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    } catch (error: any) {
      return Promise.reject(new Error(error.message));
    }
  },
  facebookPreview: async (
    facebookPreview: string | undefined,
    authToken: any,
    pageId: string | undefined,
    callToAction: string | undefined,
    headline: string | undefined,
    primaryText: string | undefined,
    description: string | undefined,
    destinationUrl:string |undefined,
    imageUrl:string|undefined
  ) => {

    try {
      const response = await axios
        .post(
          `${baseUrl}/FacebookPreview`,
          {
            "creative": {
                "object_story_spec": {
                    "link_data": {
                        "call_to_action": {
                            "type": callToAction?.replace(/\s/g, '_').toUpperCase(),
                            "value": {
                                "link": destinationUrl
                            }
                        }, 
                        "picture": imageUrl,
                        "description": description,
                        "link": destinationUrl,
                        "message": primaryText,
                        "name": headline
                    },
                    "page_id":  pageId
                }
            },
            "ad_format": facebookPreview,
        },
        
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .catch(handleUnauthorized);
      const data = response.data;
      return response.data;
    } catch (e: any) {
      console.log(e);
    }
  },
  getClientUser: async (
    userId: string,
    authToken: string
  ) => {
    try {
      const response = await axios
        .get(
          `${baseUrl}/Client/user?userId=${userId}`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .catch(handleUnauthorized);

      if (response.status === 200) {
        const data = response.data;

        return Promise.resolve({ success: true, data: data });
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    } catch (error: any) {
      return Promise.reject(new Error(error.message));
    }
  },
  getUser: async (
    userId: string,
    authToken: string
  ) => {
    try {
      const response = await axios
        .get(
          `${baseUrl}/User/${userId}`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        )
        .catch(handleUnauthorized);

      if (response.status === 200) {
        const data = response.data;

        return Promise.resolve({ success: true, data: data });
      } else {
        return Promise.reject(new Error(response.statusText));
      }
    } catch (error: any) {
      return Promise.reject(new Error(error.message));
    }
  },
};
